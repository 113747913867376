import { StatusBadge } from "../../../../util/style";
import { INPUT_TYPE, formatCurrency, formatDate } from "../../../../util/function";
import CalendarIcon from "./../../../../assets/calendar.png";
import { PaymentStatusBadge } from "./CusomStyle";

export const ANOTHER_INPUT_TYPE = {
  FILE: "Input type file",
};

export const detailTypeOf = {
  object: "[object Object]",
  array: "[object Array]",
  number: "[object Number]",
  string: "[object String]",
  boolean: "[object Boolean]",
  date: "[object Date]",
  undefined: "[object Undefined]",
  null: "[object Null]",
  map: "[object Map]",
  set: "[object Set]",
};

export const ACTION_APPROVE = {
  APPROVE: "Approve",
  REJECT: "Reject",
  DEFAULT: "default",
};

/**
 *
 * @param {any} value
 * @param {'object'|'array'|'string'|'number'|'boolean'|'undefine'|'date'|'null'|'map'|'set'} type
 * @returns {boolean}
 */
export const exactDetailTypeOf = (value, type) => {
  switch (type) {
    case "array":
      return Object.prototype.toString.call(value) === detailTypeOf.array;
    case "object":
      return Object.prototype.toString.call(value) === detailTypeOf.object;
    case "date":
      return Object.prototype.toString.call(value) === detailTypeOf.date;
    case "number":
      return Object.prototype.toString.call(value) === detailTypeOf.number;
    case "string":
      return Object.prototype.toString.call(value) === detailTypeOf.string;
    case "undefine":
      return Object.prototype.toString.call(value) === detailTypeOf.undefined;
    case "null":
      return Object.prototype.toString.call(value) === detailTypeOf.null;
    case "boolean":
      return Object.prototype.toString.call(value) === detailTypeOf.boolean;
    case "map":
      return Object.prototype.toString.call(value) === detailTypeOf.map;
    case "set":
      return Object.prototype.toString.call(value) === detailTypeOf.set;
    default:
      return false;
  }
};

export const fields = [
  {
    name: "category_id",
    type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
    label: "Category",
    defaultValue: 0,
    apiEndpoint: "/master/category-service",
    required: true,
    options: "",
  },
  {
    name: "client_id",
    type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
    apiEndpoint: "/master/clients",
    label: "Client Name",
    defaultValue: 0,
    required: true,
    options: "",
  },
  {
    name: "service_id",
    type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
    apiEndpoint: "/master/services",
    label: "Service",
    defaultValue: 0,
    required: true,
    options: "",
  },
  {
    name: "sub_service_id",
    type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
    label: "Sub Service",
    defaultValue: 0,
    required: true,
    options: "",
  },

  {
    name: "due_date",
    type: INPUT_TYPE.DATE,
    label: "Due Date",
    endIcon: <img src={CalendarIcon} />,
    formatDate: "DD/MM/YYYY", // Custom date format
    defaultValue: "",
    required: true,
  },
  {
    name: "currency",
    type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
    label: "Total",
    defaultValue: "",
    aliasLabel: "Currency",
    required: true,
    options: [
      { name: "IDR", id: "Rp" },
      { name: "USD", id: "USD" },
      { name: "EUR", id: "EUR" },
      { name: "CNY", id: "CNY" },
      { name: "SGD", id: "SGD" },
      { name: "GBP", id: "GBP" },
      { name: "AUD", id: "AUD" },
    ],
  },
  {
    name: "total_price",
    type: INPUT_TYPE.CURRENCY,
    label: "",
    aliasLabel: "Total",
    defaultValue: "",
    required: true,
  },
  {
    name: "code_billing",
    type: INPUT_TYPE.NUMBER,
    label: "Code Billing",
    defaultValue: "",
    required: true,
    options: "",
    errorMessage: "Code biling min 4, max 20",
    validation: (value) => {
      return value.length >= 4 && value.length <= 20;
    },
  },
  {
    name: "note",
    type: INPUT_TYPE.TEXTAREA,
    label: "Note",
    defaultValue: "",
    required: false,
  },

  {
    title: "Attachment",
    subtitle: "You can upload the documents required for this proses (optional)",
    isDisplayTitle: true,
    type: ANOTHER_INPUT_TYPE.FILE,
    children: [
      {
        name: "attachments",
        type: ANOTHER_INPUT_TYPE.FILE,
        label: "Upload File",
        tip: "Max size: 2 MB. Supported file: PNG, JPEG, JPG, PDF, DOC, DOCX.",
        allowedExtensions: [".pdf", ".doc", ".docx", ".jpeg", ".jpg", ".png"],
        maximumSizeInMB: 2, //MB
        defaultValue: "",
        required: false,
      },
    ],
  },
];

export const columns = [
  {
    field: "reference_code",
    headerName: "No. Reference",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "status",
    headerName: "Status",
    // description: "This column has a value getter and is not sortable.",
    sortable: true,
    width: 180,
    disableColumnMenu: true,
    valueGetter: (params) => params.value, // Return the status value as a string
    renderCell: ({ value, row }) => {
      // console.log(params);
      const { mode } = row;
      const isPaymentMode = mode && mode === "payment";
      const manipulateValue = (value) =>
        value === "Approved" && isPaymentMode ? "Waiting" : value;
      return (
        <PaymentStatusBadge status={manipulateValue(value)}>
          {manipulateValue(value)}
        </PaymentStatusBadge>
      );
    },
  },
  {
    field: "category_name",
    headerName: "Category",
    width: 210,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "due_date",
    headerName: "Due Date",
    width: 200,
    disableColumnMenu: true,
    sortable: true,
    valueGetter: (params) => formatDate(params.value),
    // renderCell: (params) => {
    //   const text = params.value || "";
    //   return text.length > 52 ? text.slice(0, 49) + "..." : text;
    // },
  },

  {
    field: "total",
    headerName: "total",
    width: 200,
    disableColumnMenu: true,
    sortable: true,
    valueGetter: ({ value, row }) => {
      let currency = row.currency;
      let optionCurrency = {
        country: undefined,
        currency: undefined,
      };
      switch (currency) {
        case "Rp":
          optionCurrency.country = "id-ID";
          optionCurrency.currency = "IDR";
          break;
        case "USD":
          optionCurrency.country = "en-EN";
          optionCurrency.currency = currency;
          break;
        default:
          optionCurrency.currency = currency;
      }
      return formatCurrency(value, optionCurrency);
    },
  },
  {
    field: "client_name",
    headerName: "Client Name",
    width: 210,
    disableColumnMenu: true,
    sortable: true,
    valueGetter: (param) => (param.value ? param.value : "-"),
  },
  {
    field: "service_name",
    headerName: "Service",
    width: 230,
    disableColumnMenu: true,
    sortable: true,
    valueGetter: (param) => (param.value ? param.value : "-"),
  },
  {
    field: "sub_service_name",
    headerName: "Sub Service",
    width: 230,
    disableColumnMenu: true,
    sortable: true,
    valueGetter: (param) => (param.value ? param.value : "-"),
    // valueGetter: (value) => {
    //   console.log(value, 'dari getter');
    //   return value;
    // }
  },
  {
    field: "create_at",
    headerName: "Create At",
    width: 200,
    disableColumnMenu: true,
    sortable: true,
    valueGetter: (params) => formatDate(params.value),
    // renderCell: (params) => (
    //   <Box
    //     sx={{
    //       "& > img": { mr: 1, flexShrink: 0 },
    //       "& > span": { color: "#555" },
    //     }}
    //   >
    //     <img
    //       loading="lazy"
    //       width="20"
    //       // srcSet={`https://flagcdn.com/w40/${params.value.code.toLowerCase()}.png 2x`}
    //       src={`https://flagcdn.com/w20/${params.value.code.toLowerCase()}.png`}
    //       alt=""
    //     />
    //     {params.value.name} <span>({params.value.code})</span>
    //   </Box>
    // ),
  },

  {
    field: "created_by",
    headerName: "Request By",
    width: 200,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "pay_at",
    headerName: "Pay At",
    width: 200,
    disableColumnMenu: true,
    sortable: true,
    valueGetter: (param) => (param.value ? formatDate(param.value) : "-"),
  },
  {
    field: "code_billing",
    headerName: "Code Billing",
    width: 200,
    disableColumnMenu: true,
    sortable: true,
    renderCell: ({ value }) => {
      return value != "0" && value ? value : "-";
    },
  },
];

/**
 *
 * @param {Date | string} inputDate
 * @returns {string}
 */
export const getTimeFromDate = (inputDate) => {
  const optionLocalTime = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  return new Date(inputDate).toLocaleTimeString("en", optionLocalTime);
};

/**
 * [object Object]
 * [object Array]
 * [object Number]
 * [object Boolean]
 * [object String]
 * [object Undefined]
 * [object Null]
 * [object Map]
 * [object Set]
 */

export const createAliasForCategory = (value) => {
  if (value !== undefined) {
    if (value.toLowerCase() === "services") {
      return "Service";
    } else if (value.toLowerCase() === "non service") {
      return "Non Service";
    }
  }
  return value;
};
